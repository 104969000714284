export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore()

  if (authStore.check && authStore.user?.workspaces_count === 0) {
    if (
      [
        "forms-create",
        "forms-slug-show-share",
        "forms-slug-edit",
        "home",
      ].includes(to.name)
    ) {
      return navigateTo({ name: "onboarding" })
    }
  }
})
